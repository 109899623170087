<template>
  <app-form-view
    multipart
    app="authentication"
    model="user"
    api-url="auth/user/"
    :title="$t('menu.user')"
    :state-items="stateItems"
    :list-route="{ name: 'user' }"
    :prepare-data="prepareData"
    v-model="formData"
    :approveItems="approveItems"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" class="d-flex">
          <app-input
            name="profile"
            type="picture"
            rules="required"
            :label="$t('fields.profile')"
            :view="view"
            :binds="{
              width: '200',
              height: '200'
            }"
            :view-binds="{
              width: '200',
              height: '200'
            }"
            v-model="formData.image"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <app-input
            name="userType"
            type="radio"
            :label="$t('label.userType')"
            :view="view"
            :items="[
              { label: $t('label.internal'), value: 'internal' },
              { label: $t('label.external'), value: 'external' }
            ]"
            v-model="formData.user_type"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="email"
            rules="required|email"
            :label="$t('fields.email')"
            :view="view"
            v-model="formData.email"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveEmail"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_email"
          />
        </v-col>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="username"
            rules="required"
            :label="$t('fields.username')"
            :view="view"
            :disabled="mode !== 'create'"
            v-model="formData.username"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveUserName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_username"
          />
        </v-col>
      </v-row>
      <v-row v-if="mode === 'create'">
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="password"
            rules="required"
            type="password"
            :label="$t('fields.password')"
            v-model="formData.password"
          />
          <app-input
            v-if="isSentToApprove"
            name="approvePassword"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_password"
          />
        </v-col>
        <v-col cols="12" md="6">
          <app-input
            name="confirmPassword"
            rules="required|confirmed:password"
            type="password"
            :label="$t('fields.confirmPassword')"
            v-model="formData.confirm_password"
          >
          </app-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="contact"
            type="select-server"
            rules="required"
            :label="$t('fields.contact')"
            :view="view"
            :binds="{
              apiUrl: 'contact/contact/?contact_type=individual&active=true'
            }"
            :filterObject="filterObject"
            v-model="formData.contact_id"
          />

          <app-input
            v-if="isSentToApprove"
            name="approveContact"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_contact"
          />
        </v-col>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="accessRight"
            type="select-server"
            rules="required"
            :label="$t('fields.accessRight')"
            :view="view"
            :binds="{
              apiUrl: 'auth/group/'
            }"
            v-model="formData.group_id"
          />

          <app-input
            v-if="isSentToApprove"
            name="approveaccessRight"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_access_right"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="startDate"
            type="datepicker"
            :label="$t('fields.startDate')"
            :view="view"
            v-model="formData.start_date"
          />

          <app-input
            v-if="isSentToApprove"
            name="approveStartDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_start_date"
          />
        </v-col>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="endDate"
            type="datepicker"
            :label="$t('fields.endDate')"
            :view="view"
            v-model="formData.end_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveEndDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_end_date"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="customer_name"
            type="select-server"
            rules="required"
            :label="$t('fields.customerCompany')"
            :view="view"
            :binds="{
              apiUrl: 'customer-company/customer_company/?active=true'
            }"
            v-model="formData.customer_company_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveCustomerCompany"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_customer_company"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="app-input--approve-wrapper">
          <app-input
            name="description"
            type="textarea"
            :label="$t('fields.description')"
            :view="view"
            v-model="formData.description"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveDesciption"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_desciption"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.is_active"
          />
        </v-col>
      </v-row>
    </template>
  </app-form-view>
</template>

<script>
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput'

export default {
  name: 'user-form',
  components: {
    AppFormView,
    AppInput
  },
  data() {
    return {
      formData: {
        state: 'draft',
        user_type: 'internal'
      },
      approveItems: [
        'approve_email',
        'approve_username',
        'approve_contact',
        'approve_access_right',
        'approve_start_date',
        'approve_end_date',
        'approve_desciption'
      ],
      activePicker: null,
      date: null,
      menu: false,
      isShow: true
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  methods: {
    prepareData(data) {
      data.contact_id = data.contact_id.id
      data.group_id = data.group_id.id
      data.customer_company_id = data.customer_company_id.id
      return data
    },
    filterObject(item, queryText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.national_id_no
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  &--content {
    position: relative;
  }
}
</style>
